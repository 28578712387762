import React from 'react';
import Card from 'components/Card/Card'; // Ensure this import is correct
import { Box, Flex, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import FormattedContentDisplay from './FormattedContentDisplay'; // Ensure this import is correct

const MarketAnalysis = () => {
  return (
    <>
      <Helmet>
        <title>AI Market Analysis | Forex Trends & Insights | Birr Value</title>
        <meta
          name="description"
          content="Get AI-driven insights on the Ethiopian forex market. Understand market trends, track bank performance, and view future predictions to make informed decisions."
        />
        <meta
          name="keywords"
          content="AI market analysis, forex trends, Ethiopian forex market, currency insights, CBE market analysis, Awash Bank forex trends, Ethiopian economy, forex insights"
        />
        <meta name="author" content="Birr Value" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://birrvalue.com/market-analysis" />
        <meta
          property="og:title"
          content="AI Market Analysis | Forex Trends & Insights | Birr Value"
        />
        <meta
          property="og:description"
          content="Access AI-driven insights on the Ethiopian forex market. Discover trends, market movements, and the impact on currency exchange rates."
        />
        <meta property="og:image" content="https://birrvalue.com/logo.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://birrvalue.com/market-analysis" />
        <meta
          property="twitter:title"
          content="AI Market Analysis | Forex Trends & Insights | Birr Value"
        />
        <meta
          property="twitter:description"
          content="Get detailed analysis of the Ethiopian forex market using AI. Understand market trends and their effects on currency rates from leading banks."
        />
        <meta property="twitter:image" content="https://birrvalue.com/logo.png" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FinancialService",
            "name": "AI Market Analysis",
            "url": "https://birrvalue.com/market-analysis",
            "description":
              "Analyze the forex market in Ethiopia with AI-driven insights. Discover how market trends impact currency exchange rates.",
            "serviceType": "Market Analysis",
            "offers": {
              "@type": "Offer",
              "priceCurrency": "ETB",
              "eligibleRegion": {
                "@type": "Country",
                "name": "Ethiopia",
              },
            },
          })}
        </script>
      </Helmet>

      <Box p="4" pt={{ base: '80px', md: '100px' }} color="#fff">
        <Card
          p="10px"
          mb="26px"
          mt="24px"
          bg="#1a1f3d"
          position="relative"
          overflow="hidden"
        >
          <Box position="relative" zIndex="2" p="16px">
            <Flex flexDirection={{ sm: 'column', lg: 'row' }} w="100%" h="100%">
              <Flex flexDirection="column" h="100%" p="22px" minW="60%" lineHeight="1.6">
                {/* Fetching and displaying the AI analysis content */}
                <FormattedContentDisplay
                  documentName="market_analysis"
                  documentTitle="AI Driven Market Analysis"
                />
              </Flex>
            </Flex>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default MarketAnalysis;
